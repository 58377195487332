/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import { message } from 'antd';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { StyledSubmitButton } from 'components/forms/masters/helper';
import { Rupee } from 'components';
import { FormInput, Box, Flex, Text, CustomInput, AsyncSelect } from 'atoms';
import {
	getUserList,
	viewDAList,
	postVehicleAllocate,
	checkCrateTracking,
} from 'store/fulfilment/deliveryAllocationSlice';
import { Debug } from 'components/forms/Debug';
import { ALLOCATION_TYPE_OPTIONS, PICK_TYPE_OPTIONS, VEHICLE_TYPE_OPTIONS } from 'utils';

const BtnWrap = styled(Flex)`
	justify-content: flex-end;
`;

const Title = styled(Text)`
	font-weight: bold;
	font-size: 16px;
`;

const E_BILL_AMOUNT = 50000;

// form schema
const FormSchema = Yup.object({
	vehicle_no: Yup.string()
		.required()
		.label('Vehicle No')
		.matches(
			/^[A-Za-z]{2}[ -]?[0-9]{1,2}(?:[ -]?[A-Za-z]{0,3})[ -]?[0-9]{4}$/,
			"Please enter a valid Vehicle Number 'eg:KA12AB1234 or KA 12 AB 1234'"
		),
	driver_name: Yup.string().required().label('Driver'),
	// driver_temperature: Yup.number()
	// 	.required()
	// 	.min(96.5)
	// 	.max(99.9)
	// 	.typeError('Not a valid temperature')
	// 	.label('Driver Body Temperature'),
	// delivery_boy_temperature: Yup.number()
	// 	.required()
	// 	.min(96.5)
	// 	.max(99.9)
	// 	.typeError('Not a valid temperature')
	// 	.label('Delivery Boy Body Temperature'),
	vendor: Yup.string().required().label('Vendor'),
	driver_mobile: Yup.string()
		.required()
		.matches(/^\d{10}$/, 'Please enter valid mobile number')
		.label('Driver Mobile Number'),
	delivery_boy: Yup.string().required().label('Delivery Boy'),
	pick_type: Yup.string().required().label('Pick Type'),
	vehicle_type: Yup.string().required().label('Vehicle Type'),
	allocation_type: Yup.string().required().label('Allocation Type'),
	e_way_bill: Yup.string()
		.matches(/^\d+$/, { message: 'Only Numbers' })
		.min(12)
		.max(12)
		.label('E Way Bill Number')
		.when('totalTruckLoadValue', {
			is: (v) => v >= E_BILL_AMOUNT,
			then: (schema) => schema.required(),
		}),
	crate_count: Yup.string()
		.matches(/^\d+$/, 'Crate count must be a valid number')
		.typeError('Crate count must be a valid number')
		.min(0, 'Crate count must be a non-negative number')
		.label('crate_count'),
	box_count: Yup.string()
		.matches(/^\d+$/, 'Box count must be a valid number')
		.typeError('Box count must be a valid number')
		.min(0, 'Box count must be a non-negative number')
		.label('box_count'),
	orders: Yup.array().of(
		Yup.object().shape({
			id: Yup.number(),
			e_way_bill: Yup.string()
				.matches(/^\d+$/, { message: 'Only Numbers' })
				.min(12)
				.max(12)
				.label('E Way Bill Number'),
		})
	),
});

const FormConfigs = [
	{
		name: 'vehicle_no',
		label: '*Vehicle No',
		type: 'text',
	},
	{
		name: 'driver_name',
		label: '*Driver',
		type: 'text',
	},
	// {
	// 	name: 'driver_temperature',
	// 	label: '*Driver Body temperature(F)',
	// },
	// {
	// 	name: 'delivery_boy_temperature',
	// 	label: '*Delivery Boy Body Temperature',
	// },
	{
		name: 'vendor',
		label: '*Vendor',
	},
	{
		name: 'driver_mobile',
		label: '*Driver Mobile Number',
	},
];

const toUserlist = (res) =>
	res?.rows?.map((x) => ({
		label: `${x.full_name || ''}`,
		value: x.id,
	})) || [];

function findDuplicates(data) {
	data = data?.map((item) => item?.e_way_bill)?.filter((item) => item !== '');
	const seen = new Set();
	const duplicates = data.filter((n) => seen.size === seen.add(n).size);
	return duplicates?.toString();
}

export default function VehicleAllocateForm({
	toggleState,
	toggledeliverCount,
	userlist = {},
	toggleInnerpopup,
	eWayBillData,
	selectedItem,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [, setModalVisible] = toggleState;
	const [, setdeliverCount] = toggledeliverCount;
	const [, setinnerPopup] = toggleInnerpopup;
	const [crateMandate, setCrateMandate] = useState();

	console.log('eWayBillData', eWayBillData);

	useEffect(() => {
		dispatch(checkCrateTracking(selectedItem?.[0])).then((res) => {
			setCrateMandate(res?.crate_tracking);
		});
	}, []);

	const initialValues = useMemo(
		() => ({
			vehicle_no: undefined,
			driver_name: undefined,
			// driver_temperature: undefined,
			// delivery_boy_temperature: undefined,
			vendor: undefined,
			driver_mobile: undefined,
			delivery_boy: undefined,
			pick_type: undefined,
			vehicle_type: undefined,
			allocation_type: undefined,
			crate_count: undefined,
			box_count: undefined,
			e_way_bill: undefined,
			totalTruckLoadValue: eWayBillData?.totalTruckLoadValue,
			orders: eWayBillData?.onlySelected?.map((item) => ({
				id: item?.id,
				e_way_bill: '',
				item: {
					invoice_no: item?.invoice_no,
					storeName: item?.Store?.name,
					total_net_amount: Number(item?.total_net_amount),
				},
			})),
		}),
		[eWayBillData]
	);

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(
			viewDAList({
				offset: 0,
				limit: 100,
				statuses: ['VA', 'OD'],
				delivery_boys: [values?.delivery_boy],
			})
		)
			.then((res) => {
				setSubmitting(false);
				setinnerPopup(res?.count > 0);
				setdeliverCount({ count: res?.count || 0, ...values });
				return { count: res?.count || 0, ...values };
			})
			.then((res) => {
				console.log(res);
				if (res?.count === 0) {
					handlePostAllocate(res);
				}
			});
	};

	const handlePostAllocate = (res) => {
		// totalTruckLoadValue is not required for Backend, so deleting it, only for internal usage
		delete res.totalTruckLoadValue;
		const { count, orders, ...rest } = res;
		/**
		 * If bill value more than 50k mean take that data other wise use normal data
		 */

		return dispatch(
			postVehicleAllocate({
				data: {
					orders: orders?.map((item) => ({
						id: item?.id,
						e_way_bill: item?.e_way_bill ? String(item?.e_way_bill) : '',
					})),
					...rest,
					fc_id: eWayBillData?.onlySelected?.[0]?.fc_id,
					// delivery_boy_temperature: +delivery_boy_temperature,
					// driver_temperature: +driver_temperature,
				},
			})
		).then(() => {
			setModalVisible(false);
			message.info('Orders have been allocated');
			navigate('/logistics-management/delivery-allocation');
		});
	};

	const handleSelect = (name, value, setFieldValue) => {
		setFieldValue(name, value?.value);
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={FormSchema}
			enableReinitialize
		>
			{({ values, setFieldValue }) => (
				<Form layout="vertical">
					<Flex flexWrap="wrap" marginBottom="20px">
						<Box width="50%" padding="0 16px">
							<FormInput name="pick_type" label="*Pick Type">
								<AsyncSelect
									name="pick_type"
									options={PICK_TYPE_OPTIONS}
									onChange={(value) => setFieldValue('pick_type', value?.value)}
									value={PICK_TYPE_OPTIONS.find(
										(option) => option.value === values.pick_type
									)}
									required
								/>
							</FormInput>
						</Box>

						<Box width="50%" padding="0 16px">
							<FormInput name="vehicle_type" label="*Vehicle Type">
								<AsyncSelect
									name="vehicle_type"
									options={VEHICLE_TYPE_OPTIONS}
									onChange={(value) =>
										setFieldValue('vehicle_type', value?.value)
									}
									value={VEHICLE_TYPE_OPTIONS.find(
										(option) => option.value === values.vehicle_type
									)}
									required
								/>
							</FormInput>
						</Box>

						<Box width="33%" padding="0 16px">
							<FormInput name="allocation_type" label="*Allocation Type">
								<AsyncSelect
									name="allocation_type"
									options={ALLOCATION_TYPE_OPTIONS}
									onChange={(value) =>
										setFieldValue('allocation_type', value?.value)
									}
									value={ALLOCATION_TYPE_OPTIONS.find(
										(option) => option.value === values.allocation_type
									)}
									required
								/>
							</FormInput>
						</Box>
					</Flex>
					<Flex flexWrap="wrap">
						{FormConfigs.map(({ name, label, type, ...rest }) => (
							<Box key={name} width="50%" padding="0 16px">
								<FormInput
									name={name}
									label={label}
									type={type}
									{...rest}
									style={{ width: '100%' }}
								>
									<CustomInput type={type} {...rest} name={name} />
								</FormInput>
							</Box>
						))}
						<Box width="50%" padding="0 16px">
							<FormInput name="delivery_boy" label="*Delivery Boy">
								<AsyncSelect
									showSearch
									name="delivery_boy"
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									allowClear
									selectOptions={toUserlist(userlist)}
									fetchOptions={getUserList}
									onChange={(value) =>
										handleSelect('delivery_boy', value, setFieldValue)
									}
									transformOptions={(val) => toUserlist(val)}
								/>
							</FormInput>
						</Box>
						{crateMandate ? (
							<Box style={{ width: '100%', display: 'flex' }}>
								<Box key="crate_count" padding="0 16px" width="50%">
									<FormInput name="crate_count" label="*Crate Count" type="text">
										<CustomInput type="text" name="crate_count" required />
									</FormInput>
								</Box>
								<Box key="box_count" padding="0 16px" width="50%">
									<FormInput name="box_count" label="Case Count" type="text">
										<CustomInput type="text" name="box_count" />
									</FormInput>
								</Box>
							</Box>
						) : null}
						{eWayBillData?.totalTruckLoadValue >= E_BILL_AMOUNT && (
							<Box width="50%" padding="0 16px">
								<FormInput name="e_way_bill" label="*E Way Bill Number">
									<CustomInput maxLength="12" name="e_way_bill" />
								</FormInput>
							</Box>
						)}
					</Flex>
					{eWayBillData?.moreThan50K?.length > 0 && (
						<Box>
							<Flex mt="2rem" mb="1rem">
								<Title width="40%" fontSize="14px">
									Invoice wise E way Bill Number
								</Title>
								<Text width="20%" fontSize="16px">
									Total Invoice: {eWayBillData?.moreThan50K?.length}
								</Text>
							</Flex>
							<Flex justifyContent="space-between">
								<Title width="40%">Invoice No / Store Name</Title>
								<Title width="20%">Invoice Value</Title>
								<Title width="40%">E-way Bill Number</Title>
							</Flex>
							<FieldArray
								name="orders"
								render={() => (
									<Box maxHeight="200px" overflow="auto">
										{values?.orders
											?.filter(
												(item) =>
													item?.item?.total_net_amount >= E_BILL_AMOUNT
											)
											.map((row, index) => (
												<Flex
													key={row?.id}
													justifyContent="space-between"
													alignItems="flex-start"
												>
													<Flex width="40%" pt="0.75rem">
														<Text fontSize="14px">{index + 1}.</Text>
														<Flex flexDirection="column" pl="1rem">
															<Text fontSize="14px">
																{row?.item?.invoice_no}
															</Text>
															<Text fontSize="14px">
																{row?.item?.storeName}
															</Text>
														</Flex>
													</Flex>
													<Flex width="20%" pt="0.75rem">
														<Rupee fontSize="14px">
															{row?.item?.total_net_amount}
														</Rupee>
													</Flex>
													<Flex width="40%">
														<FormInput
															name={`orders[${index}].e_way_bill`}
															label="E Way Bill Number"
														>
															<CustomInput
																maxLength={12}
																name={`orders[${index}].e_way_bill`}
															/>
														</FormInput>
													</Flex>
												</Flex>
											))}
									</Box>
								)}
							/>
						</Box>
					)}
					<Flex width="100%" justifyContent="flex-end">
						{findDuplicates(values.orders) && (
							<Text
								py="1rem"
								color="red"
								maxWidth="100%"
								maxHeight="100px"
								overflow="auto"
							>
								{findDuplicates(values.orders)} - Duplicate E way bill number
							</Text>
						)}
					</Flex>
					<Box>
						<BtnWrap>
							<StyledSubmitButton>Submit </StyledSubmitButton>
						</BtnWrap>
					</Box>
					<Debug show />
				</Form>
			)}
		</Formik>
	);
}

VehicleAllocateForm.propTypes = {
	toggleState: PropTypes.array,
	userlist: PropTypes.object,
	toggledeliverCount: PropTypes.array,
	toggleInnerpopup: PropTypes.array,
	eWayBillData: PropTypes.object,
	selectedItem: PropTypes.array,
};
