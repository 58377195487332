import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { API } from 'api';

const initialState = { invoiceId: null, orderDetails: null };
const COLLETION_APP_BASE_URL = process.env.REACT_APP_COLLECTION_URL;
const OMS_BASE_URL = process.env.REACT_APP_OMS;
const RETAILER_API = process.env.REACT_APP_API_RETAILER_API;

const RFC_CDMS_URL = process.env.REACT_APP_RFC_CDMS_URL;

export const getShortSettlementReason = () => async () => {
	const CREDENTIALS = {
		method: 'get',
		url: `${RFC_CDMS_URL}api/v4/reasons`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const returnToFCSlice = createSlice({
	name: 'returnToFC',
	initialState,
	reducers: {
		storeInvoiceId: (state, { payload }) => {
			state.invoiceId = payload;
		},
		storeOrderDetails: (state, { payload }) => {
			state.orderDetails = payload;
		},
	},
});

export const getReturnToFCList =
	({ offset, limit, sort_column = 'createdAt', sort_direction = 'DESC', fcs, brands, ...rest }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/allocation/list',
			data: {
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
					...rest,
				},
				page: { offset, limit },
				sort: { sort_column, sort_direction },
			},
		};
		// return API.common(CREDENTIALS).then((response) => response.data);
		return API.common(CREDENTIALS).then((response) => {
			if (response?.data?.rows.length > 0) {
				// eslint-disable-next-line no-return-assign
				response?.data?.rows.map((x) => (x.downloadStatus = 'generate'));
			}
			return response.data;
		});
	};

export const getReturnToFCShow = (id) => async () => {
	const CREDENTIALS = {
		url: `champ/order/allocation/${id}`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getReturnToFCUpdate = (id, data) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `champ/order/${id}/update`,
		data,
	};
	return API.common(CREDENTIALS).then((response) => {
		message.info(response.data.message);
		return response.data;
	});
};

export const getReturnables = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `champ/order/allocation/${id}/returnables`,
		data: { pagination: { page: 1, perPage: 1 } },
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const productReturn = (id, data) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `champ/order/${id}/return`,
		data,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const returnComplete = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `champ/order/allocation/${id}/complete`,
		data: {},
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getReturnToFCReasonList =
	(filter = {}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/reason/list',
			data: {
				filter,
				page: { limit: 25, offset: 0 },
				sort: { sort_column: 'id', sort_direction: 'DESC' },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data.rows);
	};

// #region new flow

export const getOrdersById = (id) => async () => {
	const CREDENTIALS = {
		method: 'get',
		url: `${RFC_CDMS_URL}api/v4/delivery/order/${id}`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getImagesList = (id) => async () => {
	const CREDENTIALS = {
		method: 'get',
		url: `${RETAILER_API}cdms/v1.0.0/invoice_image_list/${id}`,
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getGEOFenceStatus = (fenceData) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${RETAILER_API}cdms/retailer/geofence/status`,
		data: fenceData,
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const uploadImagesList = (uploadData) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${RETAILER_API}cdms/v1.0.0/add_invoice_image`,
		data: uploadData,
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const updateImagesList = (uploadData) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${RETAILER_API}cdms/v1.0.0/update_invoice_image`,
		data: uploadData,
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getReturnToFCReasonListNew =
	(filter = {}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/reason/list',
			data: {
				filter,
				page: { limit: 25, offset: 0 },
				sort: { sort_column: 'id', sort_direction: 'DESC' },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const updateOrderInvoiceStatus = (data, id) => async () => {
	const CREDENTIALS = {
		method: 'put',
		url: `${COLLETION_APP_BASE_URL}api/v4/delivery/order/${id}/status`,
		data,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const updateVerifyOrder = (data, id) => async () => {
	const CREDENTIALS = {
		method: 'put',
		url: `champ/order/${id}/verify`,
		data,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const orderComplete =
	(data, id, setErrors, method = 'post') =>
	async () => {
		const CREDENTIALS = {
			method,
			url: `${RFC_CDMS_URL}api/v4/delivery/order/${id}/complete`,
			data,
			setErrors,
		};
		return API.common(CREDENTIALS).then((response) => response);
	};

export const allocationComplete = (data, id) => async () => {
	const CREDENTIALS = {
		method: 'put',
		url: `${RFC_CDMS_URL}api/v4/delivery/allocation/${id}/complete`,
		data,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const rfcBlockerStatus = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_BASE_URL}api/rfc-edit-block`,
		data: {
			order_id: id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const postCrateDetails = (allocationId, crateFieldValues) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_BASE_URL}api/crate_tracking`,
		data: {
			op_type: 'set',
			allocation_id: allocationId,
			crate_log: {
				received_crate_count: crateFieldValues?.received_crate_count,
				mismatch_crate_reason: crateFieldValues?.reason,
			},
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const getCrateDetails = (allocationId) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_BASE_URL}api/crate_tracking`,
		data: {
			op_type: 'get',
			allocation_id: allocationId,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

// #endRegion

export const { storeInvoiceId, storeOrderDetails } = returnToFCSlice.actions;

export default returnToFCSlice.reducer;
