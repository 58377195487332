/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Empty, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, Flex } from 'atoms';
import { SearchBox } from 'components';
import RejectedInvoiceToggle from 'components/common/RejectedInvoiceToggle';
import { callStartreverification } from 'store/invoiceSlice';
import './invoiceCardView.scss';
import InvoiceCardViewIndivisual from './InvoiceCardViewIndivisual';

const StyledButton = styled(Button)`
	width: 180px;
`;

const BeginVerificationBox = styled(Box)`
	background-color: rgb(224, 243, 255);
	padding: 10px;
	margin: 10px 0px;
	text-align: center;
	font-weight: bold;
`;

export default function InvoiceCardView({
	invoices,
	handleVerifyReject = () => {},
	showRejection = false,
	disabled = false,
	salesmanData,
	filterRejectedInvoices,
	isValid,
	segStatus,
}) {
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState('');
	const [invoiceList, setInvoiceList] = useState(invoices);
	const [blockSalesman, setBlockSalesman] = useState(false);

	useEffect(() => {
		if (searchText?.trim()?.length === 0) {
			setInvoiceList(() => invoices);
			return;
		}

		setInvoiceList(() =>
			invoices.filter((item) => item.invoice_no.toLowerCase()?.includes(searchText))
		);
	}, [searchText, invoices]);

	function handleSearchChange(e) {
		setSearchText(e?.target?.value?.toLowerCase());
	}

	const startReverification = () => {
		dispatch(callStartreverification(salesmanData)).then((res) => {
			if (res.status === 200) {
				setBlockSalesman(true);
			} else {
				console.log('Some error occured');
			}
		});
	};

	return (
		<Box>
			<Flex justifyContent="space-between" alignItems="center">
				<Flex alignItems="center">
					<SearchBox
						onSearch={handleSearchChange}
						placeholder="Search by Invoice Number"
					/>

					<>
						<Box style={{ margin: '10px 10px 0px 20px' }}>
							<RejectedInvoiceToggle
								filterRejectedInvoices={filterRejectedInvoices}
							/>
						</Box>
						<Box style={{ marginTop: '10px', fontSize: '12px' }}>
							Only Rejected Invoices
						</Box>
					</>
				</Flex>

				<Box>
					{/* <StyledTag style={{ color: 'balck', background: '#FFCAD4' }}>Old</StyledTag>
					<StyledTag style={{ color: 'balck', background: '#AAF683' }}>Updated</StyledTag> */}
					{filterRejectedInvoices.length || invoices.length ? (
						<StyledButton
							onClick={startReverification}
							disabled={blockSalesman || salesmanData?.seg_action !== 'SUBMIT'}
						>
							{salesmanData?.verification_status
								? 'Start Re-Verification'
								: 'Start Verification'}
						</StyledButton>
					) : null}
				</Box>
			</Flex>

			{salesmanData?.seg_action === 'DEFAULT' && (
				<BeginVerificationBox>
					To begin verification, ask the salesman to click the 'Submit Collection' button.
				</BeginVerificationBox>
			)}

			{invoiceList.length ? (
				invoiceList?.map((invoice) => (
					<InvoiceCardViewIndivisual
						invoice={invoice}
						handleVerifyReject={handleVerifyReject}
						showRejection={showRejection}
						disabled={disabled}
						invoiceList={invoiceList}
						blockSalesman={blockSalesman}
						salesmanData={salesmanData}
					/>
				))
			) : (
				<Empty />
			)}
		</Box>
	);
}

InvoiceCardView.propTypes = {
	invoices: PropTypes.array,
	handleVerifyReject: PropTypes.func,
	showRejection: PropTypes.bool,
	disabled: PropTypes.bool,
	salesmanData: PropTypes.object,
	isValid: PropTypes.bool,
	segStatus: PropTypes.any,
};
