/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable sonarjs/no-duplicate-string */
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
	DesktopOutlined,
	FileOutlined,
	PieChartOutlined,
	FileTextOutlined,
} from '@ant-design/icons';
import { uniqBy, isEmpty } from 'lodash';
import * as SVGICONS from 'utils/Svg';
import { UserPersmission } from 'utils/userPermission';
import { Box, Flex } from 'atoms';
import { unScReplace } from 'utils';

// paginate Configs
export const initialPaginateConfig = {
	query: '',
	offset: 0,
	limit: 10,
	currentPage: 1,
};

export const initialPaginateConfigWithoutQ = {
	offset: 0,
	limit: 10,
	currentPage: 1,
};

// style object used in all the modules
export const searchBox = {
	width: '200px',
	margin: '5px 1rem',
	alignSelf: 'center',
};

export const CashierMenus = [
	{
		label: <Link to="/settlement">Verification</Link>,
		key: 'settlement',
		excludedroute: true,
		linkto: '/settlement',
		icon: (
			<Box color="#fff">
				<PieChartOutlined />
			</Box>
		),
		allowedgroups: UserPersmission.verification,
	},
	{
		label: <Link to="/allocation">Allocation</Link>,
		key: 'allocation',
		excludedroute: true,
		linkto: '/allocation',
		icon: (
			<Box color="#fff">
				<FileTextOutlined />
			</Box>
		),
		allowedgroups: UserPersmission.allocation,
	},
	{
		label: <Link to="/cashier">Collection Settlement</Link>,
		key: 'cashier',
		excludedroute: true,
		linkto: '/cashier',
		icon: (
			<Box color="#fff">
				<DesktopOutlined />
			</Box>
		),
		allowedgroups: UserPersmission.collectionSettlement,
	},
	{
		label: <Link to="/bank-settlement/history">Bank Settlement</Link>,
		key: 'bank-settlement',
		excludedroute: true,
		linkto: '/bank-settlement/history',
		icon: (
			<Box color="#fff">
				<FileOutlined />
			</Box>
		),
		allowedgroups: UserPersmission.bankSettlement,
	},
	{
		key: 'cheque-bounceadmin',
		label: 'Cheque Bounce',
		linkto: '/cheque-bounce',
		excludedroute: false,
		icon: <SVGICONS.ChequeBounceIcon width="20px" />,
		allowedgroups: [],
		children: [
			{
				label: (
					<Flex>
						<SVGICONS.ChequeBounceIcon width="16px" />
						<Link to="/cheque-bounce/entry-list">Cheque Bounce List</Link>
					</Flex>
				),
				linkto: '/cheque-bounce/entry-list',
				key: 'cheque-bounceadmin-1',
				allowedgroups: UserPersmission.chequeBounceAdmin,
			},
		],
	},
	// {
	// 	key: 'cheque-bounceadmin',
	// 	label: 'Cheque Bounce',
	// 	linkto: '/cheque-bounce',
	// 	excludedroute: false,
	// 	icon: <SVGICONS.ChequeBounceIcon width="20px" />,
	// 	allowedgroups: [],
	// 	children: [
	// 		{
	// 			label: <Link to="/cheque-bounce/entry-list">Cheque Bounce List</Link>,
	// 			key: 'cheque-bounceadmin-1',
	// 			excludedroute: false,
	// 			linkto: '/cheque-bounce/entry-list',
	// 			icon: (
	// 				<Box color="#fff">
	// 					<FileOutlined />
	// 				</Box>
	// 			),
	// 			allowedgroups: UserPersmission.chequeBounceAdmin,
	// 		},
	// 		// {
	// 		// 	label: (
	// 		// 		<Link to="/cheque-bounce/verification-list">Cheque Bounce Verification</Link>
	// 		// 	),
	// 		// 	key: 'cheque-bounceadmin-2',
	// 		// 	excludedroute: false,
	// 		// 	linkto: '/cheque-bounce/verification-list',
	// 		// 	icon: (
	// 		// 		<Box color="#fff">
	// 		// 			<FileOutlined />
	// 		// 		</Box>
	// 		// 	),
	// 		// 	allowedgroups: UserPersmission.chequeBounceAdmin,
	// 		// },
	// 	],
	// },
	{
		key: 'cheque-bounce',
		label: 'Cheque Bounce',
		linkto: '/cheque-bounce',
		excludedroute: true,
		icon: <SVGICONS.ChequeBounceIcon width="20px" />,
		allowedgroups: [],
		children: [
			{
				label: (
					<Flex>
						<SVGICONS.ChequeBounceIcon width="16px" />
						<Link to="/cheque-bounce/entry-list">Cheque Bounce List</Link>
					</Flex>
				),
				linkto: '/cheque-bounce/entry-list',
				key: 'cheque-bounce-1',
				allowedgroups: UserPersmission.chequeBounce,
			},
			{
				label: (
					<Flex>
						<SVGICONS.ChequeBounceIcon width="16px" />
						<Link to="/cheque-bounce/verification-list">
							Cheque Bounce Verification
						</Link>
					</Flex>
				),
				linkto: '/cheque-bounce/verification-list',
				key: 'cheque-bounce-2',
				allowedgroups: UserPersmission.checkBounceCashier,
			},
		],
	},
	// {
	// 	key: 'cheque-bounce',
	// 	label: 'Cheque Bounce',
	// 	linkto: '/cheque-bounce',
	// 	excludedroute: true,
	// 	icon: <SVGICONS.ChequeBounceIcon width="20px" />,
	// 	allowedgroups: [],
	// 	children: [
	// 		{
	// 			label: <Link to="/cheque-bounce/entry-list">Cheque Bounce List</Link>,
	// 			key: 'cheque-bounce-1',
	// 			excludedroute: true,
	// 			linkto: '/cheque-bounce/entry-list',
	// 			icon: (
	// 				<Box color="#fff">
	// 					<FileOutlined />
	// 				</Box>
	// 			),
	// 			allowedgroups: UserPersmission.chequeBounce,
	// 		},
	// 		{
	// 			label: (
	// 				<Link to="/cheque-bounce/verification-list">Cheque Bounce Verification</Link>
	// 			),
	// 			key: 'cheque-bounce-2',
	// 			excludedroute: true,
	// 			linkto: '/cheque-bounce/verification-list',
	// 			icon: (
	// 				<Box color="#fff">
	// 					<FileOutlined />
	// 				</Box>
	// 			),
	// 			allowedgroups: UserPersmission.checkBounceCashier,
	// 		},
	// 		// {
	// 		// 	label: <Link to="/cheque-bounce/cheque-bounce-form">Add Cheque Bounce</Link>,
	// 		// 	key: 'cheque-bounce-3',
	// 		// 	excludedroute: true,
	// 		// 	linkto: '/cheque-bounce/cheque-bounce-form',
	// 		// 	icon: (
	// 		// 		<Box color="#fff">
	// 		// 			<FileOutlined />
	// 		// 		</Box>
	// 		// 	),
	// 		// 	allowedgroups: UserPersmission.addChequeBounce,
	// 		// },
	// 	],
	// },
	// {
	// 	label: <Link to="/additional-settlement">Additional Settlement</Link>,
	// 	key: 'additional-settlement',
	// 	linkto: '/additional-settlement',
	// 	excludedroute: true,
	// 	icon: (
	// 		<Box color="#fff">
	// 			<AdditionalSettlementIcons />
	// 		</Box>
	// 	),
	// 	allowedgroups: UserPersmission.additionalSettlement,
	// },
];

// SideBar Menus

export const SideBarMenus = [
	{
		label: <Link to="/dashboard">Dashboard</Link>,
		key: 'dashboard',
		excludedroute: false,
		linkto: '/dashboard',
		icon: <SVGICONS.DashBoardIconss width="20px" />,
		allowedgroups: UserPersmission.dashboard,
	},
	{
		key: 'ordermanagement',
		label: 'Order Management',
		linkto: '/order-management',
		excludedroute: false,
		icon: <SVGICONS.OrderManagement width="20px" />,
		allowedgroups: [],
		children: [
			{
				label: (
					<Flex>
						<SVGICONS.SalesOrderIconss width="16px" />
						<Link to="/order-management/sales-order">Sales Order</Link>
					</Flex>
				),
				linkto: '/order-management/sales-order',
				key: 'ordermanagement-salesorder',
				allowedgroups: UserPersmission.salesOrder_list,
			},
			// {
			// 	label: (
			// 		<Flex>
			// 			<SVGICONS.SalesReturnIconss width="18px" />
			// 			<Link to="/order-management/sales-return">Sales Return</Link>
			// 		</Flex>
			// 	),
			// 	linkto: '/order-management/sales-return',
			// 	key: 'ordermanagement-sales-return',
			// 	allowedgroups: UserPersmission.salesReturn_list,
			// },
			{
				label: (
					<Flex>
						<SVGICONS.ReturnsIcon width="18px" />
						<Link to="/order-management/returns">Returns</Link>
					</Flex>
				),
				linkto: '/order-management/returns',
				key: 'ordermanagement-returns',
				allowedgroups: UserPersmission.salesReturn_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.SalesReturnIconss width="18px" />
						<Link to="/order-management/brand-sales-returns">Brand Sales Returns</Link>
					</Flex>
				),
				linkto: '/order-management/brand-sales-returns',
				key: 'ordermanagement-brand-sales-returns',
				allowedgroups: UserPersmission.salesReturn_list,
			},
		],
	},
	{
		key: 'logisticsmanagement',
		label: 'Logistics Management',
		linkto: '/logistics-management',
		excludedroute: false,
		icon: <SVGICONS.LogisticsMovement width="20px" />,
		allowedgroups: [],
		children: [
			{
				label: (
					<Flex>
						<SVGICONS.DeliveryAllocationIconss width="16px" />
						<Link to="/logistics-management/delivery-allocation">
							Delivery Allocation
						</Link>
					</Flex>
				),
				linkto: '/logistics-management/delivery-allocation',
				key: 'logisticsmanagement-deliveryallocation',
				allowedgroups: UserPersmission.deliveryAllocation_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.ReturnToFCIconss width="16px" />
						<Link to="/logistics-management/return-to-fc-new">Return To Fc</Link>
					</Flex>
				),
				linkto: '/logistics-management/return-to-fc-new',
				key: 'logisticsmanagement-returntofcnew',
				allowedgroups: UserPersmission.returnToFC_details,
			},
			// {
			// 	label: (
			// 		<Flex>
			// 			<SVGICONS.ReturnToFCIconss width="16px" />
			// 			<Link to="/logistics-management/return-to-fc">Return To Fc</Link>
			// 		</Flex>
			// 	),
			// 	linkto: '/logistics-management/return-to-fc',
			// 	key: 'logisticsmanagement-returntofc',
			// 	allowedgroups: UserPersmission.returnToFC_details,
			// },
		],
	},
	{
		key: 'warehousemanagement',
		label: 'Warehouse Management',
		excludedroute: false,
		linkto: '/warehouse-management',
		icon: <SVGICONS.FulfillmentIcons width="20px" />,
		allowedgroups: [],
		children: [
			{
				label: (
					<Flex>
						<SVGICONS.SalesOrderIconss width="16px" />
						<Link to="/warehouse-management/wms-logs">WMS Logs</Link>
					</Flex>
				),
				key: 'warehousemanagement-wmslogs',
				linkto: '/warehouse-management/wms-logs',
				allowedgroups: UserPersmission.wmslogs_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.SalesOrderIconss width="16px" />
						<Link to="/warehouse-management/po-logs">PO Logs</Link>
					</Flex>
				),
				key: 'warehousemanagement-grnlogs',
				linkto: '/warehouse-management/po-logs',
				allowedgroups: UserPersmission.pologs_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.AsnIcon width="16px" />
						<Link to="/warehouse-management/asn">ASN</Link>
					</Flex>
				),
				key: 'warehousemanagement-asn',
				linkto: '/warehouse-management/asn',
				allowedgroups: UserPersmission.grn_show,
				children: [
					{
						label: (
							<Flex>
								<SVGICONS.GrnIconss width="16px" />
								<Link to="/warehouse-management/asn/grn">GRN</Link>
							</Flex>
						),
						key: 'warehousemanagement-asn-grn',
						linkto: '/warehouse-management/asn/grn',
						allowedgroups: UserPersmission.grn_list,
					},
				],
			},

			// {
			// 	label: 'Inventory',
			// 	key: 'warehousemanagement-inventory',
			// 	linkto: '/warehouse-management/inventory',
			// 	icon: <SVGICONS.InventoryIconss width="20px" />,
			// 	allowedgroups: [],
			// 	children: [
			// 		{
			// 			label: (
			// 				<Flex>
			// 					<SVGICONS.CurrentInventoryIcons width="18px" />
			// 					<Link to="/warehouse-management/inventory/current-inventory">
			// 						Current Inventory
			// 					</Link>
			// 				</Flex>
			// 			),
			// 			key: 'warehousemanagement-inventory-currentInventory',
			// 			linkto: '/warehouse-management/inventory/current-inventory',
			// 			allowedgroups: UserPersmission.inventory_currentInventory,
			// 		},
			// 		{
			// 			label: (
			// 				<Flex>
			// 					<SVGICONS.UnsaleableIcons width="18px" />
			// 					<Link to="/warehouse-management/inventory/unsaleable-inventory">
			// 						Unsaleable Inventory
			// 					</Link>
			// 				</Flex>
			// 			),
			// 			linkto: '/warehouse-management/inventory/unsaleable-inventory',
			// 			key: 'warehousemanagement-inventory-UnsaleableInventory',
			// 			allowedgroups: UserPersmission.inventory_unsaleableInventory,
			// 		},
			// 	],
			// },
		],
	},
	{
		label: 'Onboarding',
		key: 'onboarding',
		linkto: '/onboarding',
		excludedroute: false,
		icon: <SVGICONS.OnBoardingIconss width="20px" />,
		allowedgroups: [],
		children: [
			{
				label: (
					<Flex>
						<SVGICONS.CompanyIconss />
						<Link to="/onboarding/company">Company</Link>
					</Flex>
				),
				linkto: '/onboarding/company',
				key: 'onboarding-company',
				allowedgroups: UserPersmission.company_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.ClientIconss />
						<Link to="/onboarding/client">Client</Link>
					</Flex>
				),
				linkto: '/onboarding/client',
				key: 'onboarding-client',
				allowedgroups: UserPersmission.client_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.BrandIcons width="18px" />
						<Link to="/onboarding/brand">Brand</Link>
					</Flex>
				),
				key: 'onboarding-brand',
				linkto: '/onboarding/brand',
				allowedgroups: UserPersmission.brand_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.FCIcons stroke="white" width="18px" />
						<Link to="/onboarding/fc">FC</Link>
					</Flex>
				),
				key: 'onboarding-fc',
				linkto: '/onboarding/fc',
				allowedgroups: UserPersmission.fc_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.StoreIcons width="18px" />
						<Link to="/onboarding/store">Store</Link>
					</Flex>
				),
				key: 'onboarding-stores',
				linkto: '/onboarding/store',
				allowedgroups: UserPersmission.store_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.StoreCategoryIcons width="16px" />
						<Link to="/onboarding/retail-category">Store Category</Link>
					</Flex>
				),
				key: 'onboarding-category',
				linkto: '/onboarding/retail-category',
				allowedgroups: UserPersmission.storeCategory_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.UserIcons width="16px" />
						<Link to="/onboarding/user">User</Link>
					</Flex>
				),
				key: 'onboarding-user',
				linkto: '/onboarding/user',
				allowedgroups: UserPersmission.user_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.UserIcons width="16px" />
						<Link to="/onboarding/salesman">Salesman</Link>
					</Flex>
				),
				key: 'onboarding-salesman',
				linkto: '/onboarding/salesman',
				allowedgroups: UserPersmission.salesman_list,
			},
			{
				label: (
					<Flex>
						<SVGICONS.MasterIconss width="16px" />
						<div style={{ color: '#fff' }}>Masters</div>
					</Flex>
				),

				key: 'onboarding-masters',
				// label: 'Masters',
				linkto: '/masters',
				// icon: <SVGICONS.MasterIconss width="20px" />,
				allowedgroups: UserPersmission.masters_show,
				children: [
					{
						label: (
							<Flex>
								<SVGICONS.BankIconss width="16px" />
								<Link to="/onboarding/masters/bank">Bank</Link>
							</Flex>
						),
						linkto: '/onboarding/masters/bank',
						key: 'onboarding-masters-bank',
						allowedgroups: UserPersmission.bank_list,
					},
					{
						label: (
							<Flex>
								<SVGICONS.PincodeIconss width="16px" />
								<Link to="/onboarding/masters/pincode">Pincode</Link>
							</Flex>
						),
						linkto: '/onboarding/masters/pincode',
						key: 'onboarding-masters-pincode',
						allowedgroups: UserPersmission.pincode_list,
					},
					{
						label: (
							<Flex>
								<SVGICONS.UploadIconss width="16px" />
								<Link to="/onboarding/masters/packmaster">Pack Master</Link>
							</Flex>
						),
						linkto: '/onboarding/masters/packmaster',
						key: 'onboarding-masters-packmaster',
						allowedgroups: UserPersmission.upload_list,
					},
				],
			},
		],
	},
	{
		key: 'financemanagement',
		label: 'Finance Management',
		linkto: '/finance-management',
		excludedroute: false,
		icon: <SVGICONS.FulfillmentIcons width="20px" />,
		allowedgroups: [],
		children: [
			{
				label: (
					<Flex>
						<SVGICONS.FulfillmentIcons width="16px" />
						<Link to="/finance-management/retailer-ledger">Retailer Ledger</Link>
					</Flex>
				),
				linkto: '/finance-management/retailer-ledger',
				key: 'financemanagement-transactionledger',
				allowedgroups: UserPersmission.transactionLedger_list,
			},
		],
	},
	...CashierMenus,
	// {
	// 	label: <Link to="/collections">Collections</Link>,
	// 	key: 'collections',
	// 	excludedroute: false,
	// 	linkto: '/collections',
	// 	icon: <SVGICONS.CollectionPageIcons width="20px" />,
	// 	allowedgroups: UserPersmission.collections_list,
	// },

	// {
	// 	label: <Link to="/grn">GRN</Link>,
	// 	key: 'grn',
	// 	linkto: '/grn',
	// 	icon: <SVGICONS.GrnIconss width="18px" />,
	// 	allowedgroups: UserPersmission.grn_list,
	// },
	// {
	// 	label: 'Inventory',
	// 	key: 'inventory',
	// 	linkto: '/inventory',
	// 	icon: <SVGICONS.InventoryIconss width="20px" />,
	// 	allowedgroups: [],
	// 	children: [
	// 		{
	// 			label: (
	// 				<Flex>
	// 					<SVGICONS.CurrentInventoryIcons width="18px" />
	// 					<Link to="/inventory/current-inventory">Current Inventory</Link>
	// 				</Flex>
	// 			),
	// 			key: 'inventory-currentInventory',
	// 			linkto: '/inventory/current-inventory',
	// 			allowedgroups: UserPersmission.inventory_currentInventory,
	// 		},
	// 		{
	// 			label: (
	// 				<Flex>
	// 					<SVGICONS.UnsaleableIcons width="18px" />
	// 					<Link to="/inventory/unsaleable-inventory">Unsaleable Inventory</Link>
	// 				</Flex>
	// 			),
	// 			linkto: '/inventory/unsaleable-inventory',
	// 			key: 'inventory-UnsaleableInventory',
	// 			allowedgroups: UserPersmission.inventory_unsaleableInventory,
	// 		},
	// 	],
	// },
	// {
	// 	label: <Link to="/order-adapter">Order Adapter</Link>,
	// 	key: 'orderAdapter',
	// 	linkto: '/order-adapter',
	// 	icon: <SVGICONS.UploadIconss width="20px" />,
	// 	allowedgroups: UserPersmission.orderAdapter,
	// },
	// {
	// 	label: <Link to="/settlement">Verification</Link>,
	// 	key: 'settlement',
	// 	excludedroute: true,
	// 	linkto: '/settlement',
	// 	icon: (
	// 		<Box color="#fff">
	// 			<PieChartOutlined />
	// 		</Box>
	// 	),
	// 	allowedgroups: UserPersmission.verification,
	// },
	// {
	// 	label: <Link to="/allocation">Allocation</Link>,
	// 	key: 'allocation',
	// 	excludedroute: true,
	// 	linkto: '/allocation',
	// 	icon: (
	// 		<Box color="#fff">
	// 			<FileTextOutlined />
	// 		</Box>
	// 	),
	// 	allowedgroups: UserPersmission.allocation,
	// },
	// {
	// 	label: <Link to="/cashier">Collection Settlement</Link>,
	// 	key: 'cashier',
	// 	excludedroute: true,
	// 	linkto: '/cashier',
	// 	icon: (
	// 		<Box color="#fff">
	// 			<DesktopOutlined />
	// 		</Box>
	// 	),
	// 	allowedgroups: UserPersmission.collectionSettlement,
	// },
	// {
	// 	label: <Link to="/bank-settlement/history">Bank Settlement</Link>,
	// 	key: 'bank-settlement',
	// 	excludedroute: true,
	// 	linkto: '/bank-settlement/history',
	// 	icon: (
	// 		<Box color="#fff">
	// 			<FileOutlined />
	// 		</Box>
	// 	),
	// 	allowedgroups: UserPersmission.bankSettlement,
	// },
	// {
	// 	label: <Link to="/additional-settlement">Additional Settlement</Link>,
	// 	key: 'additional-settlement',
	// 	linkto: '/additional-settlement',
	// 	excludedroute: true,
	// 	icon: (
	// 		<Box color="#fff">
	// 			<AdditionalSettlementIcons />
	// 		</Box>
	// 	),
	// 	allowedgroups: UserPersmission.additionalSettlement,
	// },
	{
		label: <Link to="/adapter-uploads">Adapter Uploads</Link>,
		key: 'adapter-uploads',
		linkto: '/adapter-uploads',
		icon: <SVGICONS.UploadIconss width="20px" />,
		allowedgroups: UserPersmission.upload_list,
	},
	// {
	// 	label: <Link to="/uploads">Uploads</Link>,
	// 	key: 'uploads',
	// 	excludedroute: false,
	// 	linkto: '/uploads',
	// 	icon: <SVGICONS.UploadIconss width="20px" />,
	// 	allowedgroups: UserPersmission.upload_list,
	// },
	{
		label: <Link to="/downloads">Downloads</Link>,
		key: 'downloads',
		linkto: '/downloads',
		icon: <SVGICONS.DownloadIconss width="20px" />,
		allowedgroups: UserPersmission.downloads,
	},
	// {
	// 	label: <Link to="/packmaster">Pack Master</Link>,
	// 	key: 'packmaster',
	// 	linkto: '/packmaster',
	// 	icon: <SVGICONS.UploadIconss width="20px" />,
	// 	allowedgroups: UserPersmission.upload_list,
	// },
	// {
	// 	key: 'fulfilment',
	// 	label: 'Fulfilment',
	// 	linkto: '/fulfilment',
	// 	icon: <SVGICONS.FulfillmentIcons width="20px" />,
	// 	allowedgroups: [],
	// 	children: [
	// 		{
	// 			label: (
	// 				<Flex>
	// 					<SVGICONS.SalesOrderIconss width="16px" />
	// 					<Link to="/fulfilment/sales-order">Sales Order</Link>
	// 				</Flex>
	// 			),
	// 			linkto: '/fulfilment/sales-order',
	// 			key: 'fulfilment-salesorder',
	// 			allowedgroups: UserPersmission.salesOrder_list,
	// 		},
	// 		{
	// 			label: (
	// 				<Flex>
	// 					<SVGICONS.DeliveryAllocationIconss width="16px" />
	// 					<Link to="/fulfilment/delivery-allocation">Delivery Allocation</Link>
	// 				</Flex>
	// 			),
	// 			linkto: '/fulfilment/delivery-allocation',
	// 			key: 'fulfilment-deliveryallocation',
	// 			allowedgroups: UserPersmission.deliveryAllocation_list,
	// 		},
	// 		{
	// 			label: (
	// 				<Flex>
	// 					<SVGICONS.ReturnToFCIconss width="16px" />
	// 					<Link to="/fulfilment/return-to-fc">Return To Fc</Link>
	// 				</Flex>
	// 			),
	// 			linkto: '/fulfilment/return-to-fc',
	// 			key: 'fulfilment-returntofc',
	// 			allowedgroups: UserPersmission.returnToFC_details,
	// 		},
	// 		{
	// 			label: (
	// 				<Flex>
	// 					<SVGICONS.SalesReturnIconss width="18px" />
	// 					<Link to="/fulfilment/sales-return">Sales Return</Link>
	// 				</Flex>
	// 			),
	// 			linkto: '/fulfilment/sales-return',
	// 			key: 'fulfilment-sales-return',
	// 			allowedgroups: UserPersmission.salesReturn_list,
	// 		},
	// 	],
	// },
];

// dashBoardData
export const CashierDashBoardData = [
	{
		id: 11,
		title: 'Verification',
		to: '/settlement',
		excludedroute: true,
		icon: <SVGICONS.VerificationIcons />,
		allowedgroups: UserPersmission.verification,
	},
	{
		id: 12,
		title: 'Allocation',
		to: '/allocation',
		excludedroute: true,
		icon: <SVGICONS.AllocationIcons />,
		allowedgroups: UserPersmission.allocation,
	},
	{
		id: 13,
		title: 'Collection Settlement',
		to: '/cashier',
		excludedroute: true,
		icon: <SVGICONS.CashVerificationIcons />,
		allowedgroups: UserPersmission.collectionSettlement,
	},
	{
		id: 14,
		title: 'Bank Settlements',
		to: '/bank-settlement/history',
		excludedroute: true,
		icon: <SVGICONS.BankSettlementIcons />,
		allowedgroups: UserPersmission.bankSettlement,
	},
	{
		id: 14,
		title: 'Cheque Bounce',
		to: '/cheque-bounce/verification-list',
		excludedroute: true,
		icon: <SVGICONS.ChequeBounceIconBlack />,
		allowedgroups: UserPersmission.checkBounceCashier,
	},

	// {
	// 	id: 15,
	// 	title: 'Additional Settlement',
	// 	to: '/additional-settlement',
	// 	excludedroute: true,
	// 	icon: <SVGICONS.AdhocIcons />,
	// 	allowedgroups: UserPersmission.additionalSettlement,
	// },
];

export const dashboardData = [
	{
		id: 1,
		title: 'Sales Order',
		to: '/order-management/sales-order',
		excludedroute: false,
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.salesOrder_list,
	},
	{
		id: 2,
		title: 'Returns',
		to: '/order-management/returns',
		excludedroute: false,
		icon: <SVGICONS.ReturnssIconss />,
		allowedgroups: UserPersmission.salesOrder_list,
	},
	{
		id: 3,
		title: 'Delivery Allocation',
		excludedroute: false,
		to: '/logistics-management/delivery-allocation',
		icon: <SVGICONS.DeliveryAllocationIconss />,
		allowedgroups: UserPersmission.deliveryAllocation_list,
	},
	// {
	// 	id: 4,
	// 	title: 'Return To FC',
	// 	to: '/logistics-management/return-to-fc',
	// 	icon: <SVGICONS.ReturnToFCIconss />,
	// 	allowedgroups: UserPersmission.returnToFC_details,
	// },
	{
		id: 4,
		title: 'Return To FC',
		excludedroute: false,
		to: '/logistics-management/return-to-fc-new',
		icon: <SVGICONS.ReturnToFCIconss />,
		allowedgroups: UserPersmission.returnToFC_details,
	},
	{
		id: 5,
		title: 'Goods Received Note',
		to: '/warehouse-management/asn/grn',
		excludedroute: false,
		icon: <SVGICONS.GrnIconss />,
		allowedgroups: UserPersmission.grn_list,
	},
	// {
	// 	id: 6,
	// 	title: 'Inventory',
	// 	to: '/warehouse-management/inventory',
	// 	excludedroute: false,
	// 	icon: <SVGICONS.InventoryIconss />,
	// 	allowedgroups: UserPersmission.inventory_currentInventory,
	// },

	{
		id: 7,
		title: 'Onboarding',
		to: '/onboarding',
		excludedroute: false,
		allowedgroups: UserPersmission.onboarding,
		icon: <SVGICONS.OnBoardingIconss stroke="#000" />,
	},

	...CashierDashBoardData,
	// {
	// 	id: 8,
	// 	title: 'Collections',
	// 	excludedroute: false,
	// 	to: '/collections',
	// 	icon: <SVGICONS.CollectionPageIcons stroke="#000" />,
	// 	allowedgroups: UserPersmission.collections_list,
	// },
	{
		id: 9,
		title: 'Adapter Uploads',
		to: '/adapter-uploads',
		icon: <SVGICONS.UploadIconss />,
		allowedgroups: UserPersmission.upload_list,
	},
	// {
	// 	id: 12,
	// 	title: 'Uploads',
	// 	to: '/uploads',
	// 	icon: <SVGICONS.UploadIconss />,
	// 	allowedgroups: UserPersmission.upload_list,
	// },
	{
		id: 100,
		title: 'Downloads',
		to: '/downloads',
		excludedroute: false,
		icon: <SVGICONS.DownloadIconss stroke="#000" />,
		allowedgroups: UserPersmission.downloads,
	},
	// {
	// 	id: 101,
	// 	title: 'Pack Master',
	// 	to: '/packmaster',
	// 	icon: <SVGICONS.UploadIconss />,
	// 	allowedgroups: UserPersmission.upload_list,
	// },
	// {
	// 	id: 10,
	// 	title: 'Stockone',
	// 	excludedroute: false,
	// 	to: '/warehouse-management/stock-one',
	// 	icon: <SVGICONS.SalesOrderIconss />,
	// 	allowedgroups: UserPersmission.wmslogs_list,
	// },
	{
		id: 10,
		title: 'WMS Logs',
		to: '/warehouse-management/wms-logs',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.wmslogs_list,
	},
	{
		id: 11,
		title: 'Cheque bounce',
		to: '/cheque-bounce/entry-list',
		icon: <SVGICONS.ChequeBounceIconBlack />,
		allowedgroups: UserPersmission.chequeBounce,
	},
	{
		id: 12,
		title: 'Retailer Ledger',
		to: '/finance-management/retailer-ledger',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.transactionLedger_list,
	},
	// {
	// 	id: 11,
	// 	title: 'GRN Logs',
	// 	to: '/warehouse-management/grn-logs',
	// 	icon: <SVGICONS.SalesOrderIconss />,
	// 	allowedgroups: UserPersmission.wmslogs_list,
	// },
];

// onBoardingdData
export const onBoardingdData = [
	{
		id: 1,
		title: 'Company',
		to: '/onboarding/company',
		icon: <SVGICONS.CompanyIconss stroke="#153e60" />,
		allowedgroups: UserPersmission.company_list,
	},
	{
		id: 2,
		title: 'Client',
		to: '/onboarding/client',
		icon: <SVGICONS.ClientIconss />,
		allowedgroups: UserPersmission.client_list,
	},
	{
		id: 3,
		title: 'Brand',
		to: '/onboarding/brand',
		icon: <SVGICONS.BrandIcons />,
		allowedgroups: UserPersmission.brand_list,
	},
	{
		id: 4,
		title: 'FC',
		to: '/onboarding/fc',
		icon: <SVGICONS.FCIcons />,
		allowedgroups: UserPersmission.fc_list,
	},
	{
		id: 5,
		title: 'Store',
		to: '/onboarding/store',
		icon: <SVGICONS.StoreIcons stroke="#153e60" />,
		allowedgroups: UserPersmission.store_list,
	},
	{
		id: 41,
		title: 'Store Category',
		to: '/onboarding/retail-category',
		icon: <SVGICONS.StoreCategoryIcons stroke="#153e60" />,
		allowedgroups: UserPersmission.storeCategory_list,
	},
	{
		id: 31,
		title: 'User',
		to: '/onboarding/user',
		icon: <SVGICONS.UserIcons stroke="#153e60" />,
		allowedgroups: UserPersmission.user_list,
	},

	{
		id: 32,
		title: 'Masters',
		to: '/onboarding/masters',
		icon: <SVGICONS.MasterIconss stroke="#153e60" />,
		allowedgroups: UserPersmission.dashboard,
	},
];

// mastersData
export const mastersData = [
	{
		id: 1,
		title: 'Bank',
		to: '/onboarding/masters/bank',
		icon: <SVGICONS.BankIconss />,
		allowedgroups: UserPersmission.upload_list,
	},
	{
		id: 2,
		title: 'Pincode',
		to: '/onboarding/masters/pincode',
		icon: <SVGICONS.PincodeIconss stroke="#153e60" />,
		allowedgroups: UserPersmission.upload_list,
	},
	{
		id: 1,
		title: 'Pack Master',
		to: '/onboarding/masters/packmaster',
		icon: <SVGICONS.UploadIconss />,
		allowedgroups: UserPersmission.upload_list,
	},
];

// inventory this part need to verify and removed

export const inventoryData = [
	{
		id: 1,
		title: 'Current Inventory',
		to: '/warehouse-management/inventory/current-inventory',
		icon: <SVGICONS.CurrentInventoryIcons />,
		allowedgroups: UserPersmission.inventory_currentInventory,
	},
	{
		id: 2,
		title: 'Unsaleable Inventory',
		to: '/warehouse-management/inventory/unsaleable-inventory',
		icon: <SVGICONS.UnsaleableIcons />,
		allowedgroups: UserPersmission.inventory_unsaleableInventory,
	},
];

// fulfillment

export const WarehousemanagementDashBoard = [
	{
		id: 1,
		title: 'WMS Logs',
		to: '/warehouse-management/wms-logs',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.wmslogs_list,
	},
	{
		id: 2,
		title: 'PO Logs',
		to: '/warehouse-management/po-logs',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.wmslogs_list,
	},
	{
		id: 3,
		title: 'Inventory',
		to: '/warehouse-management/inventory',
		icon: <SVGICONS.InventoryIconss />,
		allowedgroups: UserPersmission.dashboard,
	},
];

export const AsnDashBoard = [
	{
		id: 1,
		title: 'Goods Received Note',
		to: '/warehouse-management/asn/grn',
		icon: <SVGICONS.GrnIconss />,
		allowedgroups: UserPersmission.grn_list,
	},
];

export const OrderManagementDashBoard = [
	{
		id: 1,
		title: 'Sales Order',
		to: '/order-management/sales-order',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.salesOrder_list,
	},
	// {
	// 	id: 4,
	// 	title: 'Sales Return',
	// 	to: '/order-management/sales-return',
	// 	icon: <SVGICONS.SalesReturnIconss />,
	// 	allowedgroups: UserPersmission.salesReturn_list,
	// },
	{
		id: 3,
		title: 'Returns',
		to: '/order-management/returns',
		icon: <SVGICONS.ReturnssIconss />,
		allowedgroups: UserPersmission.salesOrder_list,
	},
];

export const FinanceManagementDashBoard = [
	{
		id: 1,
		title: 'Retailer Ledger',
		to: '/finance-management/retailer-ledger',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.transactionLedger_list,
	},
];

export const LogisticsMovementDashBoard = [
	{
		id: 2,
		title: 'Delivery Allocation',
		to: '/logistics-management/delivery-allocation',
		icon: <SVGICONS.DeliveryAllocationIconss />,
		allowedgroups: UserPersmission.deliveryAllocation_list,
	},
	// {
	// 	id: 3,
	// 	title: 'Return-to-FC',
	// 	to: '/logistics-management/return-to-fc',
	// 	icon: <SVGICONS.ReturnToFCIconss />,
	// 	allowedgroups: UserPersmission.returnToFC_details,
	// },
	{
		id: 3,
		title: 'Return-to-FC',
		to: '/logistics-management/return-to-fc-new',
		icon: <SVGICONS.ReturnToFCIconss />,
		allowedgroups: UserPersmission.returnToFC_details,
	},
];

export const fulfillmentData = [
	{
		id: 1,
		title: 'Sales Order',
		to: '/fulfilment/sales-order',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.salesOrder_list,
	},
	{
		id: 2,
		title: 'Delivery Allocation',
		to: '/fulfilment/delivery-allocation',
		icon: <SVGICONS.DeliveryAllocationIconss />,
		allowedgroups: UserPersmission.deliveryAllocation_list,
	},
	// {
	// 	id: 3,
	// 	title: 'Return-to-FC',
	// 	to: '/fulfilment/return-to-fc',
	// 	icon: <SVGICONS.ReturnToFCIconss />,
	// 	allowedgroups: UserPersmission.returnToFC_details,
	// },
	{
		id: 3,
		title: 'Return-to-FC',
		to: '/fulfilment/return-to-fc-new',
		icon: <SVGICONS.ReturnToFCIconss />,
		allowedgroups: UserPersmission.returnToFC_details,
	},
	{
		id: 4,
		title: 'Sales Return',
		to: '/fulfilment/sales-return',
		icon: <SVGICONS.SalesReturnIconss />,
		allowedgroups: UserPersmission.salesReturn_list,
	},
	{
		id: 5,
		title: 'WMS Logs',
		to: '/warehouse-management/wms-logs',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.wmslogs_list,
	},
	{
		id: 5,
		title: 'PO Logs',
		to: '/warehouse-management/po-logs',
		icon: <SVGICONS.SalesOrderIconss />,
		allowedgroups: UserPersmission.wmslogs_list,
	},
];

// static data

export const ORDER_STATUSES = [
	{ value: 'DL', label: 'Delivered' },
	{ value: 'OP', label: 'Order Placed' },
	{ value: 'OC', label: 'Order Confirmed' },
	{ value: 'PI', label: 'Ready For Pickup' },
	{ value: 'PU', label: 'Ready for Dispatch' },
	{ value: 'RA', label: 'Ready for Allocation' },
	{ value: 'VA', label: 'Vehicle Allocated' },
	{ value: 'OD', label: 'Out for Delivery' },
	{ value: 'XD', label: 'Out for Delivery' },
	{ value: 'CA', label: 'Cancelled' },
	{ value: 'DA', label: 'Delivery Attempted' },
	{ value: 'PD', label: 'Partial Delivery' },
	{ value: 'RD', label: 'Redeliver in Future' },
	// { value: 'CO', label: 'Complete' },
	{ value: 'PP', label: 'Picking In progress' },
	{ value: 'BL', label: 'Order Blocked' },
	{ value: 'OH', label: 'On Hold' },
];

export const PICK_TYPE_OPTIONS = [
	{ label: 'Party Wise Pack', value: 'PartyWisePack' },
	{ label: 'Bulk Pick', value: 'BulkPick' },
	{ label: 'Both', value: 'Both' },
];

export const VEHICLE_TYPE_OPTIONS = [
	{ label: 'Adhoc', value: 'Adhoc' },
	{ label: 'Regular', value: 'Regular' },
];

export const ALLOCATION_TYPE_OPTIONS = [
	{ label: 'Regular', value: 'Regular' },
	{ label: 'Eco', value: 'Eco' },
	{ label: 'Salesman', value: 'Salesman' },
	{ label: 'Counter Sale', value: 'CounterSale' },
];

export const ORDER_ADAPTER_STATUSES = [
	{ value: 'validation_error', label: 'Validation Error' },
	{ value: 'processing_error', label: 'Processing Error' },
	{ value: 'partially_processed', label: 'Partially Processed' },
	{ value: 'fully_processed', label: 'Fully Processed' },
	{ value: 'uploaded', label: 'Uploaded' },
];

export const RETURNS_STATUSES = [
	{ value: 'created', label: 'Created' },
	{ value: 'wms_failed', label: 'WMS Failed' },
	// { value: 'missing_qty_returns', label: 'Return Qty Missing' },
	{ value: 'all_qty_missing', label: 'All Qty Missing' },
	{ value: 'partial_qty_missing', label: 'Partial Qty Missing' },
	{ value: 'completed', label: 'Completed' },
	{ value: 'received', label: 'Received' },
	{ value: 'cancelled', label: 'Cancelled' },
];
export const STATUS_TYPES = [
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' },
];

export const RETURNS_TYPES = [
	{ value: 'SR', label: 'Sales Return' },
	{ value: 'DR', label: 'Delivery Return' },
];

export const ORDER_SEARCH_FIELDS = [
	{ value: 'order_no', label: 'Order No.' },
	// { value: 'invoice_no', label: 'Invoice No.' },
	{ value: 'vehicle_no', label: 'Vehicle No.' },
	{ value: 'store_name', label: 'Store Name' },
];

// upload view page
const dataFormat = 'DD/MM/YYYY,hh:mm:ss A';
// const getTimeTaken = (startDate, endDate) => {
// 	if (!startDate || !endDate) {
// 		return null;
// 	}
// 	return moment.utc(moment(startDate).diff(moment(endDate))).format('HH [hr] mm [min] ss [sec]');
// };

const capitalizeFirstLetter = (string) => {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return '';
};

const displayUploadedFor = (fileType) => {
	if (fileType === 'picklite_order_adapter') {
		return `Sales Order`;
	}
	if (fileType === 'picklite_grn') {
		return `Purchase Order`;
	}
	if (fileType === 'picklite_old_invoice') {
		return `Picklite old invoice`;
	}
	return `Sales Return`;
};

export const getFileId = ({ data }) => [
	{
		id: 7,
		label: 'File id',
		value: data.id,
	},
];

export const UploadShowDetails = ({ data }) => [
	// {
	// 	id: 1,
	// 	label: 'Uploaded at',
	// 	value: moment(data?.createdAt).isValid() ? moment(data?.createdAt).format(dataFormat) : '',
	// },
	// {
	// 	id: 2,
	// 	label: 'Completed at',
	// 	value: moment(data?.lastPickAt).isValid()
	// 		? moment(data?.lastPickAt).format(dataFormat)
	// 		: '',
	// },
	// {
	// 	id: 3,
	// 	label: 'Total order',
	// 	value: data?.totalOrders || 0,
	// },
	// {
	// 	id: 4,
	// 	label: 'Total put quantity',
	// 	value: data?.totalPutQty || 0,
	// },
	// {
	// 	id: 5,
	// 	label: 'First Pick At',
	// 	value: moment(data?.firstPickAt).isValid()
	// 		? moment(data?.firstPickAt).format(dataFormat)
	// 		: '',
	// },
	// {
	// 	id: 6,
	// 	label: 'Total time taken',
	// 	value: getTimeTaken(data?.lastPickAt, data?.firstPickAt),
	// },
	// {
	// 	id: 7,
	// 	label: 'Total Product',
	// 	value: data?.totalProducts || 0,
	// },
	// {
	// 	id: 8,
	// 	label: 'Total Crates',
	// 	value: data?.totalCrates || 0,
	// },

	{
		id: 1,
		label: 'Uploaded for',
		value: displayUploadedFor(data?.fileType),
		// value: data?.fileType?.replaceAll('_', ' ').replace('picklite', '').toUpperCase(),
	},
	{
		id: 2,
		label: 'Brand',
		value: data?.BrandName,
	},
	{
		id: 3,
		label: 'FC',
		value: data?.FCName,
	},
	{
		id: 4,
		label: 'Uploaded by',
		value: data?.UploadedByName,
	},
	{
		id: 5,
		label: 'Uploaded at',
		value: moment(data?.createdAt).isValid() ? moment(data?.createdAt).format(dataFormat) : '',
	},
	{
		id: 6,
		label: 'Status',
		value: capitalizeFirstLetter(data?.fileStatus?.replace('_', ' ')),
		// value: data?.fileStatus === 'fully_processed' ? 'Fully Processed' : 'Partially Processed',
	},
];

export const selectOptionsFunc = (options) =>
	options.map((x) => ({
		label: x.name,
		value: x.id,
	}));

export const toAsysnOption = (res) =>
	res?.rows?.map((x) => ({
		label: `${x.code || ''}: ${x.name || ''}`,
		value: x.id,
		brands:
			x?.Brands?.map((y) => ({
				label: `${y.code || ''}: ${y.name || ''}`,
				value: y.id,
			})) || [],
	})) || [];

export const toAsysnOrderAdapterOption = (res) =>
	res?.rows?.map((x) => ({
		label: `${x.code || ''}: ${x.name || ''}`,
		value: x.code,
		brands:
			x?.Brands?.map((y) => ({
				label: `${y.code || ''}: ${y.name || ''}`,
				value: y.code,
			})) || [],
	})) || [];

export const toAsysnPickersOption = (res) =>
	res?.rows?.map((x) => ({
		label: `${x.full_name || ''}`,
		value: x.id,
	})) || [];

export const toAsysnBankOption = (res) =>
	res?.rows?.map((x) => ({
		label: `${x.name || ''}`,
		value: x.id,
	})) || [];

export function fcBrands(fcs) {
	return fcs?.map((fc) => ({
		label: unScReplace(fc?.name),
		value: fc?.id,
		children: fc?.Brands?.map((brand) => ({
			label: `${unScReplace(fc?.name)} - ${unScReplace(brand?.name)}`,
			value: brand?.id,
		})),
	}));
}

export function SelectOptions(res, name, value) {
	return (
		res?.rows?.map((x) => ({
			label: `${x[name] || ''}`,
			value: x[value],
		})) || []
	);
}

export const SelectFilterOptions = (input, option) =>
	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const SelectFilterLabelOptions = (input, option) => {
	console.log('input ', option);
	return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const SelectFilterNameOptions = (input, option) => {
	console.log('input ', input);
	console.log('option ', option);
	return option?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const DownloadFile = (res) => {
	if (isEmpty(res?.url)) return;
	const elem = document.createElement('a');
	elem.setAttribute('href', res?.url);
	elem.setAttribute('download', res?.name);
	elem.click();
	elem.remove();
};

// return to fc options
export const DeliverStatusOptions = [
	{ label: 'Delivered', value: 'DL' },
	{ label: 'Partial Delivered', value: 'PD' },
	{ label: 'Cancelled', value: 'CA' },
	{ label: 'Delivery Attempted', value: 'DA' },
];

export const DeliverStatusOptionsForWarehouseDue = [
	{ label: 'Partial Delivered', value: 'PD' },
	{ label: 'Cancelled', value: 'CA' },
	{ label: 'Delivery Attempted', value: 'DA' },
];

const uploadUrl = 'champ/file/upload';
export const UPLOAD_FILE_TYPE_CHOICE = [
	// { id: 'picklite_order_adapter', name: 'Order Adapter', url: 'champ/file/order/upload' },
	{ id: 'picklite_order', name: 'Sales Order', url: 'champ/file/order/upload' },
	{ id: 'picklite_grn', name: 'GRN', url: uploadUrl },
	{ id: 'picklite_stock', name: 'Stock Adjustment', url: uploadUrl },
	{ id: 'picklite_return', name: 'Sales Return', url: uploadUrl },
	{ id: 'picklite_old_invoice', name: 'OBC', url: uploadUrl },
];

export const ADAPTER_UPLOAD_FILE_TYPE_CHOICE = [
	{ id: 'picklite_order_adapter', name: 'Sales Order', url: 'champ/file/order/upload' },
	{ id: 'picklite_grn', name: 'Purchase Order', url: uploadUrl },
	{ id: 'pack_master', name: 'Pack Master', url: 'masters/pack_master/' },
	{ id: 'sales_return', name: 'Sales Return', url: uploadUrl },
	{ id: 'picklite_old_invoice', name: 'OBC', url: uploadUrl },
];

export const WMS_STATUS_CHOICE = [
	{
		id: 'CREATED',
		name: 'Created',
	},
	{
		id: 'FAILED',
		name: 'Failed',
	},
];

export const UploadExcelFilterOptions = (data) => {
	switch (data?.groups?.[0].name) {
		case 'Billing Executive':
			return [{ value: 'picklite_order', label: 'Sales Order' }];
		default:
			return UPLOAD_FILE_TYPE_CHOICE.map((x) => ({
				label: x.name,
				value: x.id,
			}));
	}
};

export const transformFCBrandsOptions = (data) => {
	const uniqeFCs = [];
	const refactoredValues = uniqBy(
		data?.rows
			?.map((x) =>
				x?.fc?.map((y) => {
					if (!uniqeFCs.includes(y.id)) uniqeFCs.push({ value: y.id, label: y?.code });

					return {
						value: `${y.id}_${x.id}`,
						label: `${y?.code || ''}: ${x?.name || ''}`,
					};
				})
			)
			.flat(),
		(x) => x.value
	);

	const combineFcAllBrand = uniqBy(
		uniqeFCs
			.map((x) => {
				const filterByFcs = refactoredValues.filter(
					(y) => parseInt(y?.value?.split('_')?.[0], 10) === x?.value
				);
				if (filterByFcs.length > 1) {
					const brandIds = filterByFcs
						.map((z) => parseInt(z?.value?.split('_')?.[1], 10))
						.join('_');
					return [
						{ value: `all_${x.value}_${brandIds}`, label: `${x?.label}: All Brands` },
						...filterByFcs,
					];
				}
				return false;
			})
			.flat()
			.filter((x) => x),
		(x) => x.value
	);

	return uniqBy([...combineFcAllBrand, ...refactoredValues], (x) => x.value);
};

export const handleFCBrands = (values) => {
	if (isEmpty(values)) {
		return { brands: [], fcs: [] };
	}

	const brandIds = uniqBy(
		values
			?.map((x) => {
				if (x.value?.split('_').includes('all')) {
					return x?.value
						?.split('_')
						?.slice(2)
						.map((y) => +y);
				}
				return parseInt(x?.value?.split('_')?.[1], 10);
			})
			.flat(),
		(x) => x
	);

	const getFCsIds = uniqBy(
		values?.map((x) => {
			if (x.value?.split('_').includes('all')) {
				return parseInt(x?.value?.split('_')?.[1], 10);
			}

			return parseInt(x?.value?.split('_')?.[0], 10);
		}),
		(x) => x
	);

	return { brands: brandIds, fcs: getFCsIds };
};

export const fcBrandsDefaultValue = (data, paginateConfig, fcs) =>
	data?.filter((x) => {
		if (x.value?.split('_').includes('all')) {
			const hasEveryBrandId = x?.value
				?.split('_')
				?.slice(2)
				.map((y) => +y);
			return hasEveryBrandId.every((y) => paginateConfig?.includes(y));
		}

		if (isEmpty(fcs)) {
			return paginateConfig?.includes(x?.value);
		}

		return (
			paginateConfig?.includes(parseInt(x?.value?.split('_')?.[1], 10)) &&
			fcs?.includes(parseInt(x?.value?.split('_')?.[0], 10))
		);
	});

export function getunique(sourceData, key) {
	return uniqBy(
		sourceData?.map((item) => ({
			text: unScReplace(item?.[key]),
			value: item?.[key],
		})),
		'value'
	);
}

export function date(dueDate) {
	return moment(dueDate).format('DD/MM/YYYY');
}
export const FcListOptions = [
	'BTM',
	'BZA-VGA1',
	'CHN1',
	'CHN2',
	'CJB',
	'HMR',
	'HUBLI',
	'HYDA',
	'HYDM',
	'HYDMPST',
	'PUNE1',
	'PYA',
	'YPR',
	'GDY',
	'TLB',
];

export const BrandListBasedFcs = {
	BTM: ['BRIT', 'DBR', 'LOREAL', 'NVA', 'RKT', 'GDJGT'],
	CHN1: ['BRIT'],
	CJB: ['NVA'],
	HMR: ['BRIT', 'DBR', 'GDJGT', 'KBC', 'LOREAL', 'HUL'],
	HUBLI: ['GDJ', 'RKT'],
	HYDA: ['BRIT', 'HUL'],
	PYA: ['ITC', 'TATA'],
	YPR: ['NVA', 'BRIT', 'HUL'],
	HYDM: ['BRIT', 'GDJ'],
	CHN2: ['GDJ'],
	PUNE1: ['RB', 'BRIT'],
	'BZA-VGA1': ['BRIT'],
	HYDMPST: ['BRIT', 'RB', 'DBR'],
	GDY: ['CLGT'],
	TLB: ['HUL'],
	PNY: ['HUL'],
};

export const ORDER_STATUSES_ENUM = [
	{ DL: 'Delivered' },
	{ OP: 'Order Placed' },
	{ OC: 'Order Confirmed' },
	{ PI: 'Ready For Pickup' },
	{ PU: 'Ready for Dispatch' },
	{ RA: 'Ready for Allocation' },
	{ VA: 'Vehicle Allocated' },
	{ OD: 'Out for Delivery' },
	{ XD: 'Out for Delivery' },
	{ CA: 'Cancelled' },
	{ DA: 'Delivery Attempted' },
	{ PD: 'Partial Delivery' },
	{ RD: 'Redeliver in Future' },
	{ CO: 'Complete' },
	{ PP: 'Picking In progress' },
	{ BL: 'Order Blocked' },
];
